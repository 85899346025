import Vue from "vue";
import Vuex from "vuex";
import car from "./modules/car";
import cms from "./modules/cms";
import company from "./modules/company";
import emails from "./modules/emails";
import emailtexts from "./modules/emailtexts";
import mechanic from "./modules/mechanic";
import product from "./modules/product";
import session from "./modules/session";
import snackbar from "./modules/snackbar";

Vue.use(Vuex);

export default new Vuex.Store({
    "modules": {
        car,
        emails,
        emailtexts,
        cms,
        company,
        mechanic,
        product,
        session,
        snackbar
    },
    "strict": process.env.VUE_APP_NODE_ENV !== "production"
});
