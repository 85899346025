<template>
    <div class="textEdit">
        <h2>{{ currentEmail.name }}</h2>
        <v-form ref="textForm" @submit.prevent="postEmail">
            <v-text-field
                v-model="name"
                label="Name"
            />
            <v-text-field
                v-model="subject"
                label="Onderwerp"
            />
            <v-text-field
                v-model="template"
                label="Template"
            />
            <v-btn color="primary" class="ma-2" type="submit">
                Save
            </v-btn>
        </v-form>
    </div>
</template>

<script>

export default {
    "name": "EmailEdit",
    "components": {
    },
    data () {
        return {
        };
    },
    created () {
        if (!this.$store.state.company.currentCompany.id) {
            this.$router.push("/email");
        }
    },
    "methods": {
        postEmail () {
            this.updateObject(
                "currentEmail.companyId",
                this.$store.state.company.currentCompany.id
            );
            this.$store.dispatch("emails/postEmail")
                .then(() => this.$router.push({ "name": "EmailList" }));
        },
        updateObject (
            key,
            value
        ) {
            this.$store.dispatch(
                "emails/updateObject",
                {
                    "object": key,
                    "value": value || ""
                }
            );
        }
    },
    "computed": {
        currentEmail () {
            return this.$store.state.emails.currentEmail;
        },
        "name": {
            get () {
                return this.currentEmail.name;
            },
            set (value) {
                this.updateObject(
                    "currentEmail.name",
                    value
                );
            }
        },
        "subject": {
            get () {
                return this.currentEmail.subject;
            },
            set (value) {
                this.updateObject(
                    "currentEmail.subject",
                    value
                );
            }
        },
        "template": {
            get () {
                return this.currentEmail.template;
            },
            set (value) {
                this.updateObject(
                    "currentEmail.template",
                    value
                );
            }
        }
    },
    beforeDestroy () {
        this.$store.dispatch("emails/resetEmail");
    }
};
</script>
