import * as _ from "lodash";
import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "emailTexts": [],
    "currentEmailText": {
        "emailId": 0,
        "name": "",
        "content": ""
    }
});

const state = getDefaultState();

const mutations = {
    UPDATE_OBJECT (state, data) {
        _.set(
            state,
            data.object,
            data.value
        );
    },
    RESET_CURRENT_EMAILTEXT (state) {
        state.currentEmailText = getDefaultState().currentEmailText;
    },
    REMOVE_EMAILTEXT (state, id) {
        const index = state.emailTexts.findIndex((item) => item.id === id);
        state.emailTexts.splice(
            index,
            1
        );
    },
    SET_EMAILTEXTS (state, emailTexts) {
        state.emailTexts = emailTexts;
    },
    SET_CURRENT_EMAILTEXT (state, emailText) {
        state.currentEmailText = emailText;
    }
};

const getters = {};

const actions = {
    async updateObject ({commit}, params) {
        await commit(
            "UPDATE_OBJECT",
            params
        );
    },
    async getEmailTextsByEmailId ({commit}, emailId) {
        await api.get(
            `v1/emails/${emailId}/texts`,
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_EMAILTEXTS",
                    response.data
                );
            });
    },
    // eslint-disable-next-line no-unused-vars
    putEmailText ({commit}) {
        const emailText = {...state.currentEmailText };
        return api.put(
            `v1/emails/${emailText.emailId}/texts/${emailText.id}`,
            emailText,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .catch((error) => {
                alert(error);
            });
    },
    // eslint-disable-next-line no-unused-vars
    postEmailText ({commit}) {
        const emailText = { ...state.currentEmailText };
        return api.post(
            `v1/emails/${emailText.emailId}/texts`,
            emailText,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        ).catch((error) => {
            alert(error);
        });
    },
    getEmailTextById ({commit}, id) {
        // eslint-disable-next-line no-shadow
        const emailText = state.emailTexts.find((emailText) => emailText.id === parseInt(
            id,
            10
        ));
        commit("RESET_CURRENT_EMAILTEXT");
        if (emailText) {
            commit(
                "SET_CURRENT_EMAILTEXT",
                emailText
            );
        }
    },
    resetEmailText ({commit}) {
        commit("RESET_CURRENT_EMAILTEXT");
    },
    async deleteEmailText ({commit}, emailText) {
        try {
            await api.delete(
                `v1/emails/${emailText.emailId}/texts/${emailText.id}`,
                {},
                {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            );
            commit(
                "REMOVE_EMAILTEXT",
                emailText.id
            );
            commit("RESET_CURRENT_EMAILTEXT");
        } catch (e) {
            alert(e);
        }
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
