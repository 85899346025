<template>
    <div class="textEdit">
        <h2>{{ currentEmailText.name }}</h2>
        <v-form
            ref="textForm"
            @submit.prevent="putEmailText">
            <v-text-field
                v-model="name"
                label="name"
            />
            <vue-editor
                :editorToolbar="customToolbar"
                v-model="content"
            ></vue-editor>
            <v-btn
                color="primary"
                class="ma-2"
                type="submit"
            >
                Opslaan
            </v-btn>
            <v-btn
                color="error"
                class="ma-2"
                @click="deleteEmailText"
            >
                Verwijderen
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
export default {
    "name": "EmailtextEdit",
    "components": {
        VueEditor
    },
    data () {
        return {
            "customToolbar": [
                [{"header": [false, 1, 2, 3, 4, 5, 6]}], ["bold", "italic", "underline", "strike"],
                [{"align": ""}, {"align": "center"}, {"align": "right"}, {"align": "justify"}],
                ["blockquote"],
                [{"list": "ordered"}, {"list": "bullet"}, {"list": "check"}],
                [{"indent": "-1"}, {"indent": "+1"}],
                [{"color": []}, {"background": []}],
                ["link"], ["clean"]
            ]
        };
    },
    created () {
        if (!this.$route.params.id || !this.$route.params.textId || !this.$store.state.company.currentCompany.id) {
            this.$router.push("/email");
            return;
        }
        this.fetchEmailText();
    },
    "methods": {
        async fetchEmailText () {
            await this.$store.dispatch(
                "emailtexts/getEmailTextById",
                this.$route.params.textId
            );
        },
        updateObject (object, value) {
            const updateValue = typeof value === "undefined" ? "" : value;
            this.$store.dispatch(
                "emailtexts/updateObject",
                {
                    object,
                    "value": updateValue
                }
            );
        },
        putEmailText () {
            this.$store.dispatch("emailtexts/putEmailText")
                .then(() => {
                    this.$router.push({
                        "name": "EmailEdit",
                        "params": {"id": this.$route.params.id}
                    });
                });
        },
        async deleteEmailText () {
            try {
                await this.$store.dispatch(
                    "emailtexts/deleteEmailText",
                    this.currentEmailText
                );
                this.$router.push({
                    "name": "EmailEdit",
                    "params": {"id": this.$route.params.id}
                });
            } catch (e) {
                // This should handle errors
            }
        }
    },
    "computed": {
        "currentEmailText" () {
            return this.$store.state.emailtexts.currentEmailText;
        },
        "name": {
            get () {
                return this.currentEmailText.name;
            },
            set (value) {
                this.updateObject(
                    "currentEmailText.name",
                    value
                );
            }
        },
        "content": {
            get () {
                return this.currentEmailText.content;
            },
            set (value) {
                this.updateObject(
                    "currentEmailText.content",
                    value
                );
            }
        }
    },
    beforeDestroy () {
        this.$store.dispatch("emailtexts/resetEmailText");
    }
};
</script>
