<template>
    <div class="textEdit">
        <h2>{{ currentText.label }}</h2>
        <v-form ref="textForm" @submit.prevent="postText">
            <v-select
                v-model="type"
                :items="editorOptions"
                label="Editor type"
                class="mb-3"
            />
            <v-text-field
                v-model="name"
                label="Name"
            />
            <v-text-field
                v-model="label"
                label="Label"
            />
            <component
                :is="currentText.type === 'wysiwyg' ? 'vue-editor' : 'v-text-field'"
                v-model="content"
                :editor-toolbar="customToolbar"
                label="Content"
            />
            <v-btn color="primary" class="ma-2" type="submit">
                Save
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
    "name": "TextEdit",
    "components": { VueEditor },
    data () {
        return {
            "customToolbar": [
                [{ "header": [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [{ "align": "" }, { "align": "center" }, { "align": "right" }, { "align": "justify" }],
                ["blockquote"],
                [{ "list": "ordered" }, { "list": "bullet" }, { "list": "check" }],
                [{ "indent": "-1" }, { "indent": "+1" }],
                [{ "color": [] }, { "background": [] }],
                ["link"],
                ["clean"]
            ],
            "editorOptions": [
                {
                    "text": "WYSIWYG",
                    "value": "wysiwyg"
                },
                {
                    "text": "Text Field",
                    "value": "textfield"
                }
            ]
        };
    },
    created () {
        if (!this.$store.state.company.currentCompany.id) {
            this.$router.push("/text");
        }
    },
    "methods": {
        postText () {
            this.updateObject(
                "currentText.companyId",
                this.$store.state.company.currentCompany.id
            );
            this.$store.dispatch("cms/postText")
                .then(() => this.$router.push({ "name": "TextList" }));
        },
        updateObject (
            key,
            value
        ) {
            this.$store.dispatch(
                "cms/updateObject",
                {
                    "object": key,
                    "value": value || ""
                }
            );
        }
    },
    "computed": {
        currentText () {
            return this.$store.state.cms.currentText;
        },
        "name": {
            get () {
                return this.currentText.name;
            },
            set (value) {
                this.updateObject(
                    "currentText.name",
                    value
                );
            }
        },
        "content": {
            get () {
                return this.currentText.content;
            },
            set (value) {
                this.updateObject(
                    "currentText.content",
                    value
                );
            }
        },
        "label": {
            get () {
                return this.currentText.label;
            },
            set (value) {
                this.updateObject(
                    "currentText.label",
                    value
                );
            }
        },
        "type": {
            get () {
                return this.currentText.type;
            },
            set (value) {
                this.updateObject(
                    "currentText.type",
                    value
                );
            }
        }
    },
    beforeDestroy () {
        this.$store.dispatch("cms/resetText");
    }
};
</script>
