<template>
    <div class="textEdit">
        <h2>{{ currentEmail.name }}</h2>
        <v-form
            ref="textForm"
            @submit.prevent="putEmail">
            <v-text-field
                v-model="template"
                label="Template"
            />
            <v-data-table
                id="emailList"
                :headers="headers"
                :items="emailTexts"
                item-key="id"
                disable-pagination
                hide-default-footer
                @click:row="gotoEdit"
            ></v-data-table>
            <v-btn
                color="primary"
                class="ma-4"
                :to="`/email/${this.$route.params.id}/text/create`"
            >
                Nieuw tekst blok
            </v-btn>
            <v-btn
                color="primary"
                class="ma-2"
                type="submit"
            >
                Opslaan
            </v-btn>
            <v-btn
                color="error"
                class="ma-2"
                @click="deleteEmail"
            >
                Verwijderen
            </v-btn>
        </v-form>
    </div>
</template>

<script>
export default {
    "name": "EmailEdit",
    "components": {
    },
    data () {
        return {
            "headers": [
                {
                    "text": "Name",
                    "sortable": true,
                    "value": "name"
                },
                {
                    "text": "",
                    "value": "actions",
                    "width": "72px",
                    "sortable": false
                }
            ]
        };
    },
    created () {
        if (!this.$route.params.id || !this.$store.state.company.currentCompany.id) {
            this.$router.push("/email");
            return;
        }
        this.fetchEmail();
    },
    "methods": {
        gotoEdit (item) {
            this.$router.push({
                "name": "EmailtextEdit",
                "params": {
                    "id": this.currentEmail.id,
                    "textId": item.id
                }
            });
        },
        async fetchEmail () {
            await this.$store.dispatch(
                "emailtexts/getEmailTextsByEmailId",
                this.$route.params.id
            );
            await this.$store.dispatch(
                "emails/getEmailById",
                this.$route.params.id
            );
        },
        updateObject (object, value) {
            const updateValue = typeof value === "undefined" ? "" : value;
            this.$store.dispatch(
                "emails/updateObject",
                {
                    object,
                    "value": updateValue
                }
            );
        },
        putEmail () {
            this.$store.dispatch("emails/putEmail")
                .then(() => {
                    this.$router.push({"name": "EmailList"});
                });
        },
        async deleteEmail () {
            try {
                await this.$store.dispatch(
                    "emails/deleteEmail",
                    this.currentEmail
                );
                this.$router.push({"name": "EmailList"});
            } catch (e) {
                // This should handle errors
            }
        }
    },
    "computed": {
        "currentEmail" () {
            return this.$store.state.emails.currentEmail;
        },
        "emailTexts" () {
            return this.$store.state.emailtexts.emailTexts;
        },
        "template": {
            get () {
                return this.currentEmail.template;
            },
            set (value) {
                this.updateObject(
                    "currentEmail.template",
                    value
                );
            }
        }
    },
    beforeDestroy () {
        this.$store.dispatch("emails/resetEmail");
    }
};
</script>
