<template>
    <div class="textList">
        <h2>Teksten</h2>
        <v-select
          v-model="selectedCompanyId"
          :items="companies.all"
          item-text="name"
          item-value="id"
          label="Select company"
          class="my-4"
          @change="handleCompanyChange"
        ></v-select>
        <v-data-table
            id="textList"
            :headers="headers"
            :items="texts"
            item-key="id"
            disable-pagination
            hide-default-footer
            @click:row="gotoEdit"
        ></v-data-table>
        <v-btn
            v-if="selectedCompanyId"
            color="primary"
            class="mt-4"
            :to="`/text/create`"
        >
            Maak nieuwe text
        </v-btn>
    </div>
</template>

<script>
export default {
    "name": "TextList",
    "components": {},
    data () {
        return {
            "headers": [
                {
                    "text": "Name",
                    "sortable": true,
                    "value": "label"
                },
                {
                    "text": "",
                    "value": "actions",
                    "width": "72px",
                    "sortable": false
                }
            ]
        };
    },
    "methods": {
        gotoEdit (item) {
            this.$router.push({
                "name": "TextEdit",
                "params": {"id": item.id}
            });
        },
        handleCompanyChange (selectedCompanyId) {
            const company = this.companies.all.filter((c) => c.id === selectedCompanyId)[0];

            this.$store.dispatch(
                "company/setActiveCompany",
                company
            );
            this.$store.dispatch(
                "cms/getTextsByCompanyId",
                selectedCompanyId
            );
        },
        createNewText () {
            this.$router.push({
                "name": "TextCreate",
                "params": {
                    "companyId": this.selectedCompanyId
                }
            });
        }
    },
    "created" () {
        this.$store.dispatch("company/getCompanies");
        if (this.selectedCompanyId) {
            this.$store.dispatch(
                "cms/getTextsByCompanyId",
                this.selectedCompanyId
            );
        }
    },
    "computed": {
        "texts" () {
            return this.$store.state.cms.texts;
        },
        "companies" () {
            return {
                "all": Object.values(this.$store.state.company.companies).map((company) => ({
                    "id": company.id,
                    "name": company.name
                })),
                "selected": this.$store.state.company.currentCompany || {}
            };
        },
        "selectedCompanyId": {
            get () {
                return this.companies.selected.id;
            },
            set (value) {
                this.$store.dispatch(
                    "company/setActiveCompany",
                    value
                );
            }
        }
    }
};
</script>
