<template>
    <div class="textEdit">
        <h2>{{ currentEmailText.name }}</h2>
        <v-form ref="textForm" @submit.prevent="postEmailText">
            <v-text-field
                v-model="name"
                label="Name"
            />
            <vue-editor
                :editorToolbar="customToolbar"
                v-model="content"
            ></vue-editor>
            <v-btn color="primary" class="ma-2" type="submit">
                Save
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
export default {
    "name": "EmailTextEdit",
    "components": {
        VueEditor
    },
    data () {
        return {
            "customToolbar": [
                [{"header": [false, 1, 2, 3, 4, 5, 6]}], ["bold", "italic", "underline", "strike"],
                [{"align": ""}, {"align": "center"}, {"align": "right"}, {"align": "justify"}],
                ["blockquote"],
                [{"list": "ordered"}, {"list": "bullet"}, {"list": "check"}],
                [{"indent": "-1"}, {"indent": "+1"}],
                [{"color": []}, {"background": []}],
                ["link"], ["clean"]
            ]
        };
    },
    created () {
        if (!this.$store.state.company.currentCompany.id || !this.$store.state.emails.currentEmail.id) {
            this.$router.push("/email");
        }
    },
    "methods": {
        postEmailText () {
            this.updateObject(
                "currentEmailText.emailId",
                this.$route.params.id
            );
            this.$store.dispatch("emailtexts/postEmailText")
                .then(() => {
                    this.$router.push({
                        "name": "EmailEdit",
                        "params": {"id": this.$route.params.id}
                    });
                });
        },
        updateObject (
            key,
            value
        ) {
            this.$store.dispatch(
                "emailtexts/updateObject",
                {
                    "object": key,
                    "value": value || ""
                }
            );
        }
    },
    "computed": {
        "currentEmailText" () {
            return this.$store.state.emailtexts.currentEmailText;
        },
        "name": {
            get () {
                return this.currentEmailText.name;
            },
            set (value) {
                this.updateObject(
                    "currentEmailText.name",
                    value
                );
            }
        },
        "content": {
            get () {
                return this.currentEmailText.content;
            },
            set (value) {
                this.updateObject(
                    "currentEmailText.content",
                    value
                );
            }
        }
    },
    beforeDestroy () {
        this.$store.dispatch("emailtexts/resetEmailText");
    }
};
</script>
