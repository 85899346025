import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "companies": [],
    "currentCompany": {}
});

const state = getDefaultState();

const mutations = {
    SET_COMPANIES (state, companies) {
        state.companies = companies;
    },
    SET_CURRENT_COMPANY (state, currentCompany) {
        state.currentCompany = currentCompany;
    }
};

const getters = {};

const actions = {
    async getCompanies ({ commit }) {
        if (state.companies.length > 0) {
            return;
        }
        try {
            const response = await api.get(
                "v1/companies",
                {
                    "headers": {
                        "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                    }
                }
            );
            commit(
                "SET_COMPANIES",
                response.data
            );
        } catch (e) {
            // This should handle error
        }
    },
    setActiveCompany (
        { commit },
        company
    ) {
        commit(
            "SET_CURRENT_COMPANY",
            company
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
