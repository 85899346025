import * as _ from "lodash";
import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "emails": [],
    "currentEmail": {
        "companyId": 0,
        "name": "",
        "subject": "",
        "template": ""
    }
});

const state = getDefaultState();

const mutations = {
    UPDATE_OBJECT (state, data) {
        _.set(
            state,
            data.object,
            data.value
        );
    },
    RESET_CURRENT_EMAIL (state) {
        state.currentEmail = getDefaultState().currentEmail;
    },
    REMOVE_EMAIL (state, id) {
        const index = state.emails.findIndex((item) => item.id === id);
        state.emails.splice(
            index,
            1
        );
    },
    SET_EMAILS (state, emails) {
        state.emails = emails;
    },
    SET_CURRENT_EMAIL (state, email) {
        state.currentEmail = email;
    }
};

const getters = {};

const actions = {
    async updateObject ({commit}, params) {
        await commit(
            "UPDATE_OBJECT",
            params
        );
    },
    async getEmails ({commit}) {
        const companyId = 1;
        await api.get(
            `v1/companies/${companyId}/emails`,
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_EMAILS",
                    response.data
                );
            });
    },
    async getEmailsByCompanyId ({commit}, companyId) {
        await api.get(
            `v1/companies/${companyId}/emails`,
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_EMAILS",
                    response.data
                );
            });
    },
    // eslint-disable-next-line no-unused-vars
    putEmail ({commit}) {
        const email = {...state.currentEmail};
        return api.put(
            `v1/companies/${email.companyId}/emails/${email.id}`,
            email,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .catch((error) => {
                alert(error);
            });
    },
    // eslint-disable-next-line no-unused-vars
    postEmail ({commit}) {
        const email = { ...state.currentEmail };
        return api.post(
            `v1/companies/${email.companyId}/emails`,
            email,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        ).catch((error) => {
            alert(error);
        });
    },
    getEmailById ({commit}, id) {
        // eslint-disable-next-line no-shadow
        const email = state.emails.find((email) => email.id === parseInt(
            id,
            10
        ));
        commit("RESET_CURRENT_EMAIL");
        if (email) {
            commit(
                "SET_CURRENT_EMAIL",
                email
            );
        }
    },
    resetEmail ({commit}) {
        commit("RESET_CURRENT_EMAIL");
    },
    async deleteEmail ({commit}, email) {
        try {
            await api.delete(
                `v1/companies/${email.companyId}/emails/${email.id}`,
                {},
                {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            );
            commit(
                "REMOVE_EMAIL",
                email.id
            );
        } catch (e) {
            alert(e);
        }
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
